import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import {
  AccountLazy,
  FoxLazy,
  JoinLazy,
  ManageLazy,
  OnboardingLazy,
  OpsLazy,
  PageNotFoundLazy,
  RegisterLazy,
  RootLazy,
  SignupLazy,
  VisitLazy,
} from "./Apps";
import { SplashScreen } from "./Components/Layout/SplashScreen";

export const URL_PREFIX = {
  ACCOUNT: "/account",
  FOX: "/fox",
  MANUAL: "/manual",
  OPS: "/ops",
  MANAGE: "/manage",
  ONBOARDING: "/onboarding",
  JOIN: "/join",
  VISIT: "/visit",
} as const;

export const appNameToUrlPrefix = {
  Account: URL_PREFIX.ACCOUNT,
  Fox: URL_PREFIX.FOX,
  Manual: URL_PREFIX.MANUAL,
  OpsPortal: URL_PREFIX.OPS,
  Manage: URL_PREFIX.MANAGE,
  Join: URL_PREFIX.JOIN,
  Visit: URL_PREFIX.VISIT,
} as const;

export default function AppRoutes() {
  return (
    <Suspense fallback={<SplashScreen />}>
      <Routes>
        <Route path={`${URL_PREFIX.ACCOUNT}/*`} element={<AccountLazy />} />
        <Route path={`${URL_PREFIX.FOX}/*`} element={<FoxLazy />} />
        <Route path={`${URL_PREFIX.OPS}/*`} element={<OpsLazy />} />
        <Route path={`${URL_PREFIX.MANAGE}/*`} element={<ManageLazy />} />
        <Route
          path={`${URL_PREFIX.ONBOARDING}/*`}
          element={<OnboardingLazy />}
        />
        <Route path={`${URL_PREFIX.JOIN}/*`} element={<JoinLazy />} />
        <Route path={`${URL_PREFIX.VISIT}/*`} element={<VisitLazy />} />
        <Route path={"/signup"} element={<SignupLazy />} />
        <Route path={"/register"} element={<RegisterLazy />} />
        <Route path="/" element={<RootLazy />} />
        {/* fallback path in case none above it match */}
        <Route path="*" element={<PageNotFoundLazy />} />
      </Routes>
    </Suspense>
  );
}
