import { MsalProvider } from "@azure/msal-react";
import CssBaseline from "@mui/material/CssBaseline";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import AppRoutes from "./AppRoutes";
import { CustomNavigationClient } from "./Context/UserAuth/msal/CustomNavigationClient";
import { SnackbarProvider } from "./Features/Snackbar";

function App({ pca }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <SnackbarProvider>
        <CssBaseline />
        <AppRoutes />
      </SnackbarProvider>
    </MsalProvider>
  );
}

App.propTypes = {
  pca: PropTypes.object,
};

export default App;
