import { Alert } from "@mui/material";
import PropTypes from "prop-types";

import { useErrorTranslator } from "../../Hooks/Error";

export function SplashError({ error }) {
  const et = useErrorTranslator();

  return (
    <Alert sx={{ marginBottom: 2 }} severity="error">
      {et(error)}
    </Alert>
  );
}

SplashError.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
};
