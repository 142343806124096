import { Theme, createTheme } from "@mui/material";
import * as polished from "polished";

type StyleOverrideProps = { theme: Omit<Theme, "components"> };

const defaultFontFamily = [
  "Open Sans",
  "Roboto",
  "Helvetica",
  "Arial",
  "sans-serif",
].join(",");

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#F7F7F7" /* theme.palette.background.main */,
          color: "#1A1A1A" /* theme.palette.text.main */,
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        color: "secondary",
        variant: "contained",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.borderRadius.md,
          textTransform: "none",
          textDecoration: "none",
          padding: "9px 16px",
          whiteSpace: "nowrap",
          "& svg": {
            width: "20px",
            height: "20px",
          },
          disabled: ({ theme }: StyleOverrideProps) => ({
            opacity: theme.opacity.disabled,
          }),
        }),
        contained: {
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
        },
        containedPrimary: {
          "&:hover": {
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #CBD733",
          },
        },
        containedSuccess: {
          "&:hover": {
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #2F9A0F",
          },
        },
        containedError: {
          "&:hover": {
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #E64441",
          },
        },
        containedSecondary: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.text.main,
          "&:hover": {
            background: "rgba(0, 0, 0, 0.08)",
          },
        }),
        textSecondary: ({ theme }) => ({
          color: theme.palette.text.main,
        }),
        containedSizeSmall: () => ({
          borderRadius: "14px",
          padding: "5px 12px",
        }),
        textSizeSmall: () => ({
          borderRadius: "14px",
          padding: "5px 12px",
        }),
        containedSizeLarge: ({ theme }) => ({
          borderRadius: theme.borderRadius.lg,
          padding: "13px 20px",
        }),
        textSizeLarge: ({ theme }) => ({
          borderRadius: theme.borderRadius.lg,
          padding: "13px 20px",
        }),
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: "contained" } as FIXME,
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
          },
        },
        {
          props: { variant: "contained", color: "primary" } as FIXME,
          style: {
            backgroundColor: "#cbd733",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
          },
        },
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.main,
        }),
        sizeSmall: () => ({
          borderRadius: "14px",
          fontSize: "20px",
          padding: "7px",
        }),
        sizeMedium: ({ theme }) => ({
          borderRadius: theme.borderRadius.md,
          fontSize: "20px",
          padding: "10px",
        }),
        sizeLarge: ({ theme }) => ({
          borderRadius: theme.borderRadius.lg,
          fontSize: "20px",
          padding: "14px",
        }),
        colorPrimary: {
          "&:hover": {
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #CBD733",
          },
        },
        colorSecondary: {
          "&:hover": {
            background: "rgba(0, 0, 0, 0.08)",
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
        color: "secondary",
      },
      styleOverrides: {
        underlineHover: {
          "&:hover": {
            textDecoration: "none",
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 16,
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rounded: {
          borderRadius: "18px",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          height: "40px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
        },
        head: ({ theme }) => ({
          color: theme.palette.grey[800],
          fontSize: "0.8125rem",
          fontWeight: 400,
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          borderRight: "1px solid rgba(0, 0, 0, 0.05)",
          "&th:first-of-type": {
            paddingLeft: theme.spacing(4),
          },
          "&th:last-of-type": {
            paddingRight: theme.spacing(4),
          },
        }),
        body: ({ theme }) => ({
          "&td:first-of-type": {
            paddingLeft: theme.spacing(4),
          },
          "&td:last-of-type": {
            paddingRight: theme.spacing(4),
          },
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "rgba(0, 0, 0, 0.05)",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.main,
        }),
      },
    },
    MuiBadge: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiInputBase: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.main,
          minHeight: "40px",
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.borderRadius.xs,
        }),
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        slotProps: {
          paper: {
            elevation: 4,
            sx: {
              borderRadius: (theme) => theme.borderRadius.sm,
            },
          },
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          // the rendering here seems to differ from the docs
          // so we're setting the color explicitly
          ".MuiAutocomplete-popupIndicator": {
            color: theme.palette.text.secondary,
          },
          ".MuiAutocomplete-clearIndicator": {
            color: theme.palette.text.secondary,
          },
        }),
        // same theming as MuiSelect
        paper: ({ theme }) => ({
          ".MuiAutocomplete-listbox": {
            padding: theme.spacing(1),
            maxHeight: 400,
          },
          ".MuiAutocomplete-option": {
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
            borderRadius: theme.spacing(1),
          },
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          // TODO: After MUI update
          // check slotProps.paper are available
          PaperProps: {
            elevation: 4,
            sx: {
              maxHeight: 400,
              borderRadius: (theme) => theme.borderRadius.sm,
            },
          },
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          ".MuiSelect-icon": {
            // Our Icon components have a different
            // sizing that the MUI icon components
            width: "20px",
            height: "20px",
            right: "10px",
            top: "calc(50% - 10px)",
            color: theme.palette.text.secondary,
          },
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.main,
          borderRadius: theme.borderRadius.md,
          border: "1px solid rgba(0, 0, 0, 0.05)",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.03)",
          padding: theme.spacing(1, 2),
          [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap",
          },
        }),
        action: ({ theme }) => ({
          padding: theme.spacing(0, 0, 0, 2),
          marginRight: 0,
        }),
        standardInfo: ({ theme }) => ({
          backgroundColor: theme.palette.info.light,
        }),
        standardSuccess: ({ theme }) => ({
          backgroundColor: theme.palette.success.light,
        }),
        standardWarning: ({ theme }) => ({
          backgroundColor: theme.palette.warning.light,
        }),
        standardError: ({ theme }) => ({
          backgroundColor: theme.palette.error.light,
        }),
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: () => ({
          fontWeight: 600,
        }),
      },
    },
    MuiChip: {
      defaultProps: {
        size: "small",
        variant: "outlined",
      },
      styleOverrides: {
        root: ({ theme }: StyleOverrideProps) => ({
          borderRadius: theme.borderRadius.xs,
          lineHeight: 1,
          fontWeight: 400,
        }),
        sizeSmall: ({ theme }: StyleOverrideProps) => ({
          fontSize: theme.typography.body3.fontSize,
          height: "24px",
          lineHeight: "24px",
        }),
        labelSmall: () => ({
          padding: "0 10px",
        }),
        sizeMedium: ({ theme }: StyleOverrideProps) => ({
          fontSize: theme.typography.body2.fontSize,
          height: "28px",
          lineHeight: "28px",
        }),
        labelMedium: () => ({
          padding: "2px 10px",
        }),
        outlined: () => ({
          border: "none",
        }),
        outlinedAccent: ({ theme }: StyleOverrideProps) => ({
          backgroundColor:
            "light" in theme.palette.accent
              ? theme.palette.accent.light
              : undefined,
        }),
        outlinedSecondary: ({ theme }: StyleOverrideProps) => ({
          backgroundColor: theme.palette.secondary.light,
          color: "rgba(0, 0, 0, 0.55)",
        }),
        outlinedError: ({ theme }: StyleOverrideProps) => ({
          backgroundColor: theme.palette.error.light,
        }),
        outlinedSuccess: ({ theme }: StyleOverrideProps) => ({
          backgroundColor: theme.palette.success.light,
        }),
        outlinedWarning: ({ theme }: StyleOverrideProps) => ({
          backgroundColor: theme.palette.warning.light,
        }),
        outlinedInfo: ({ theme }: StyleOverrideProps) => ({
          backgroundColor: theme.palette.info.light,
        }),
      } as FIXME,
    },
    MuiAvatar: {
      styleOverrides: {
        root: () => ({
          border: "1px solid rgba(0, 0, 0, 0.1)",
          width: "40px",
          height: "40px",
          borderRadius: "17px",
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.02)",
          borderRadius: theme.borderRadius.md,
          "&:hover": {
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
          },
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: () => ({
          padding: 0,
          margin: "24px",
        }),
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: () => ({
          padding: 0,
          margin: "24px",
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          background: "rgba(0, 0, 0, 0.9)",
          color: "white",
          fontSize: polished.rem(14),
          fontWeight: 400,
          lineHeight: polished.rem(22),
          padding: theme.spacing(2, 3),
          borderRadius: theme.borderRadius.sm,
          boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginLeft: theme.spacing(0.5),
          marginRight: theme.spacing(0.5),
          fontSize: theme.typography.body3.fontSize,
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          fontSize: theme.typography.body2.fontSize,
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.Mui-error": {
            color: theme.palette.text.secondary,
          },
          "&.Mui-focused": {
            color: theme.palette.text.secondary,
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: () => ({
          backgroundColor: "rgba(0, 0, 0, 0.9)",
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2.5, 3),
          minWidth: "80px",
          fontSize: polished.rem(15),
          fontWeight: 600,
          lineHeight: polished.rem(22),
          color: theme.palette.text.secondary,
          "&.Mui-selected": {
            color: "rgba(0, 0, 0, 0.9)",
          },
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: ({ theme }) => ({
          padding: theme.spacing(1),
          // This is a hack to prevent added padding in MuiSelect when it scrolls
          paddingRight: theme.spacing(1) + " !important",
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: theme.palette.grey[200],
          },
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
          borderRadius: theme.spacing(1),
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          minWidth: "unset",
          marginRight: theme.spacing(1),
          color: theme.palette.text.main,
          "& svg": {
            width: 20,
            height: 20,
          },
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& td:first-of-type": {
            paddingLeft: theme.spacing(3),
          },
          "& td:last-of-type": {
            paddingRight: theme.spacing(3),
          },
          "& th:first-of-type": {
            paddingLeft: theme.spacing(3),
          },
          "& th:last-of-type": {
            paddingRight: theme.spacing(3),
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& a": {
            color: theme.palette.info.main,
            textDecoration: "none",
          },
        }),
      },
    },
  },
  avatarSize: {
    48: {
      width: "48px",
      height: "48px",
      borderRadius: "20px",
    },
    40: {
      width: "40px",
      height: "40px",
      borderRadius: "17px",
    },
    32: {
      width: "32px",
      height: "32px",
      borderRadius: "13px",
    },
    28: {
      width: "28px",
      height: "28px",
      borderRadius: "12px",
    },
    24: {
      width: "24px",
      height: "24px",
      borderRadius: "10px",
    },
    20: {
      width: "20px",
      height: "20px",
      borderRadius: "8px",
    },
    16: {
      width: "16px",
      height: "16px",
      borderRadius: "7px",
    },
  },
  borderRadius: {
    xs: "6px",
    sm: "12px",
    md: "18px",
    lg: "21px",
  },
  opacity: {
    disabled: 0.45,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 760,
      md: 1024,
      lg: 1260,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      light: "rgba(203, 215, 51, 0.12)",
      main: "#CBD733",
      contrastText: "#000",
    },
    accent: {
      light: "rgba(123, 79, 155, 0.12)",
      main: "#7B4F9B",
      contrastText: "#FFF",
    },
    secondary: {
      light: "rgba(0, 0, 0, 0.05)",
      main: "rgba(0, 0, 0, 0.3)",
      contrastText: "#FFF",
    },
    error: {
      light: "rgba(230, 68, 65, 0.12)",
      main: "#E64441",
      contrastText: "#FFF",
    },
    success: {
      light: "rgba(47, 154, 15, 0.12)",
      main: "#2f9A0f",
      contrastText: "#FFF",
    },
    warning: {
      light: "rgba(218, 125, 40, 0.12)",
      main: "#DA7D28",
      contrastText: "#FFF",
    },
    info: {
      light: "rgba(18, 142, 212, 0.12)",
      main: "#128ED4",
      contrastText: "#FFF",
    },
    grey: {
      50: "rgba(0, 0, 0, 0.02)",
      100: "rgba(0, 0, 0, 0.03)",
      200: "rgba(0, 0, 0, 0.05)",
      300: "rgba(0, 0, 0, 0.08)",
      400: "rgba(0, 0, 0, 0.12)",
      500: "rgba(0, 0, 0, 0.15)",
      600: "rgba(0, 0, 0, 0.3)",
      700: "rgba(0, 0, 0, 0.55)",
      800: "rgba(0, 0, 0, 0.6)",
      900: "rgba(0, 0, 0, 0.9)",
    },
    background: {
      main: "#F7F7F7",
      paper: "#FFF",
      accent: "#F2F5CC",
    },
    text: {
      main: "#1A1A1A",
      secondary: "#666666",
    },
  },
  typography: {
    fontFamily: defaultFontFamily,
    h1: {
      fontSize: polished.rem(24),
      fontWeight: 700,
      lineHeight: polished.rem(34),
    },
    h2: {
      fontSize: polished.rem(20),
      fontWeight: 600,
      lineHeight: polished.rem(28),
    },
    h3: {
      fontSize: polished.rem(16),
      fontWeight: 600,
      lineHeight: polished.rem(24),
    },
    h4: {
      fontSize: polished.rem(15),
      fontWeight: 600,
      lineHeight: polished.rem(22),
    },
    body1: {
      fontSize: polished.rem(15),
      fontWeight: 400,
      lineHeight: polished.rem(24),
    },
    body2: {
      fontSize: polished.rem(14),
      fontWeight: 400,
      lineHeight: polished.rem(22),
    },
    body3: {
      fontSize: polished.rem(13),
      fontWeight: 400,
      lineHeight: polished.rem(20),
    },
    button: {
      fontSize: polished.rem(14),
      fontWeight: 600,
      lineHeight: polished.rem(22),
      textTransform: "none",
    },
    overline: {
      fontSize: polished.rem(14),
      fontWeight: 600,
      lineHeight: polished.rem(22),
      textTransform: "uppercase",
    },
  },
});
